import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide, Images} from "../customComponents";
import {useHistory} from 'react-router-dom';

import {authActions} from '../../redux/actions';
import Helper1, {notify} from '../../utility/Helper1';
import  APIRequests from '../../utility/APIRequests';
import Countdown from 'react-countdown';
import {systemColor} from '../../utility/styles';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import AuthHolder from "./AuthHolder";
import { RiEyeOffFill, RiEyeFill } from "react-icons/ri";

import { useSelector, useDispatch } from 'react-redux';

function ForgetPassword(){

    const [emailInput, setEmailInput] = useState('') 
    const [newPassword, setNewPassword] = useState('') 
    const [confirmNewPassword, setConfirmNewPassword] = useState('') 

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [emailCode, setEmailCode] = useState('')
    const [requestOTPRetObj, setRequestOTPRetObj] = useState({})
    const [uiLevel, setUiLevel] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    
    const [loadingText, setLoadingText]         = useState("");
    const [loading, changeLoading]         = useState(false);

    
    const history                                       = useHistory();
    const systemColorManager = systemColor()


    const requestOTP = async (e) => {
        
        e.preventDefault();
        setFullPageLoading(true);

        

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password_pin", {email: emailInput});

        if(getPost?.code == 1){
            setUiLevel(1)
            setRequestOTPRetObj(getPost?.data)
        }

        notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);

    }


   

    const changePassword = async (e) => {

        e.preventDefault();


        if((newPassword != confirmNewPassword) || (newPassword == '')){
            notify("Passwords must match", 0)
            return;
        }

        changeLoading(true)
        setLoadingText("Please wait...")
        
        setFullPageLoading(true);

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password", {email: emailInput, password:newPassword, passwordResetCode:emailCode});

        if(getPost?.code == 1){
            setUiLevel(0)
            notify("Password reset successful. You are being redirected to login page", 1)
            
            setTimeout(() => {
                history.replace('/signin');
            }, 5000)
        }else{
            notify(getPost?.msg, 0)
        }

        changeLoading(false)
        setLoadingText("")

        // notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);


    }

    return (<SiteWide loading={loading} overlayText={loadingText} title="Reset password">
        <AuthHolder customHeadline="RESET PASSWORD">



                        {uiLevel == 0 && <div>
                            
                             <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Account Email ID*</span>
                                <input className="auth-input" onChange={e => setEmailInput(e.target.value) } placeholder="Email ID" required type="email" required style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}}/>
                            </label>


                            <div className="container-login100-form-btn mt-40">
                                <button  onClick={requestOTP} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">
                                    Reset Password
                                </button>
                            </div>


                        </div>
                        }


                        {uiLevel == 1 && <div>
                            
                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>New Password*</span>
                                <input className="auth-input" onChange={e => setNewPassword(e.target.value) } placeholder="New Password" required type="email" required type="password" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}}/>
                            </label>

                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Confirm Password*</span>
                                <input className="auth-input" onChange={e => setConfirmNewPassword(e.target.value) } placeholder="Confirm Password" required type="password" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}}/>
                            </label>


                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Email Token*</span>
                                <input className="auth-input" onChange={e => setEmailCode(e.target.value) } placeholder="Email Token" required type="text" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}}/>
                            </label>


                            <div>
                                <span>
                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        date={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <span className="design-text-for-click">Expired. Reload to try again</span>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <span>Expires in {minutes}:{seconds}</span>;
                                          }
                                        }}
                                    />
                                </span>

                            </div>



                            <div className="container-login100-form-btn mt-40">
                                <button  onClick={changePassword} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">
                                    Change Password
                                </button>
                            </div>


                        </div>
                        }


                        <div style={{textAlign: 'center', marginTop: 25, ...systemColorManager.txt_1}}>
                            
                            <WebLink style={{marginLeft: 'auto'}} className="forgot-btn"  style={{...systemColorManager.txt_2}} to={'/signin'}>
                                Sign In
                            </WebLink>

                        </div>



                    </AuthHolder>
                </SiteWide>

    );

}

export default ForgetPassword;