import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";

import {systemColor} from '../../utility/styles';
import { AiFillStar, AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import EachFixture from "./EachFixture"
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";
import { IoSearchOutline, IoCloseOutline, IoArrowForwardCircleOutline } from "react-icons/io5";
import { RiCloseCircleLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper';


import DownModal from "../layouts/DownModal";




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}


const leagueSliders = {
    slidesToShow: 5.4,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}


const topToBottom = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  verticalSwiping: true,
};



function AllFixtures() {

    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Loading history...');
    const footballData                      = useSelector(footballSelect);

    const [loading, setloading]             = useState(false);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    
    const fullFootballData = byNextDate(footballData?.fixtures)
    const [homeFixture, setHomeFixture]   = useState(fullFootballData);
    const [selectedLeagues, setSelectedLeagues]   = useState(null);
    const [allTodaysCountToShow, setAllTodaysCountToShow]     = useState(30);
    const [searchLevel, setSearchLevel]     = useState(true);
    const [searchText, setSearchText]     = useState('');
    
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const accessParam = urlParams.get('leaguename');

    const history = useHistory();
    const systemColorManager = systemColor()

    const toggleLeagueAndSearch = () => {
        showAllLeague()
        setSearchLevel(!searchLevel)
    }

    const eachIsClicked = (eachFixture) => {
        history.push(`fixture/${eachFixture?.fixture_id}`)
    }


    const sortByLeague = () => {


        const groupedData = fullFootballData?.reduce((acc, curr) => {
        const { league_name } = curr;
        
        if (!acc[league_name]) {
            acc[league_name] = [];
        }

        acc[league_name].push(curr);
            return acc;
        }, {});

        let leagueResult;

        if (groupedData && typeof groupedData === 'object') {
            leagueResult = Object.values(groupedData);
        } 

        return leagueResult
    }

    let leagues_name = sortByLeague();

    


        

    useEffect(() => {

        let coincidenceLeague = sortByLeague()?.filter(eachLeague => {

            return eachLeague?.[0]?.['league_name'] == accessParam

        })

        if(coincidenceLeague?.length > 0){
            leagueToShow(coincidenceLeague?.[0])
        }

    }, [accessParam])


    const leagueToShow = (newArray) => {
        setSelectedLeagues(byNextDate([...newArray]))
    }

    const showAllLeague = () => {
        setSelectedLeagues(null)
    }



    // # when league is filtered by
    let fixtureDataToUse = homeFixture
    if(selectedLeagues){
        fixtureDataToUse = selectedLeagues
    }

    if(!searchLevel){
        fixtureDataToUse = homeFixture?.filter(
                                                item => item.teams_home_name.match(new RegExp(searchText, "i")) || 
                                                item.teams_away_name.match(new RegExp(searchText, "i")) ||
                                                item.league_name.match(new RegExp(searchText, "i")) ||
                                                item.league_country.match(new RegExp(searchText, "i"))

                                            )
    }





    const rightTopMenu = () => {
        return <div style={{color: '#000', marginRight: 10, width: 25, height: 25, backgroundColor: '#31ff00', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 10}}>
                    {fixtureDataToUse.length ?? 0}
                </div>
    }


    

    return (

        <SiteWide loading={loading} overlayText={loadingText} rightTopMenu={rightTopMenu} title={'Fixtures'}>
            
           


                <div className="row no-gutter">

                    <div className="col-12">

                        <div className="prem h4" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...systemColorManager.tickerStrip_text}}>
                            
                            <div>
                                {!searchLevel?'Search':'Leagues'}
                            </div>

                            <div className="flexify" style={{paddingTop: 6}}>
                                
                                {selectedLeagues && <span onClick={showAllLeague} className="cursor-pointer" style={{marginRight: 10}}>
                                    <RiCloseCircleLine className="" />
                                </span>}

                                <span onClick={toggleLeagueAndSearch} className="cursor-pointer" style={{marginRight: 10}}>
                                    {(searchText =='' || searchLevel) && <IoSearchOutline className="" />}
                                    {!searchLevel && searchText !='' && <IoCloseOutline className="" />}
                                </span>


                            </div>

                        </div>

                        
                        <div>
                        
                            {searchLevel && <div style={{height: 60}} className="show">
                                <Slider className="main-home-slider" arrows={false} {...leagueSliders}>

                                    {leagues_name?.map((item, index) => {

                                        return <div className="cursor-pointer" onClick={() => leagueToShow(item)}>
                                                <div title={`${item?.[0].league_name} - ${item?.[0].league_country} (${item?.length ?? 0})`} className="" 
                                                    style={{width: 60, height: 60, 
                                                    backgroundColor: (selectedLeagues?.[0]?.league_name == item?.[0]?.league_name)?'#31ff00':'#eaeaea', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 450}}>

                                                    <Images style={{marginRight: 5, width: 30, height: 30}} source={item?.[0]?.league_logo} alt={item?.[0]?.league_name} />
                                                </div>
                                        </div>

                                    })}
                                  
                                </Slider>
                            </div>}

                            {!searchLevel == 1 && <div style={{height: 60}} className="show">

                                <input value={searchText} onChange={(e) => setSearchText(e.target.value)} style={
                                        {
                                            backgroundColor: '#fff', 
                                            color: '#000',
                                            width: '100%',
                                            border: '3px solid #2689c8',
                                            borderRadius: 50,
                                            height: 60,
                                            margin: 0,
                                            fontSize: 30,
                                            paddingLeft: 10,
                                        }
                                    } />
                                <div className="text-center"><small>Filter by league, country and team name</small></div>
                            </div>}

                        </div>

                    </div>
                    
                </div>


                <br /><br />




                <div className="row no-gutter">

                    <div className="col-12">

                        <div className="inter-section">
                        
                            <div className="prem h4" style={{...systemColorManager.tickerStrip_text}}>
                                {selectedLeagues?`${selectedLeagues?.[0]?.league_country} ${selectedLeagues?.[0]?.league_name}`:'All Matches'}
                            </div>

                            <div>

                                {fixtureDataToUse.slice(0, allTodaysCountToShow)?.map((item) => {

                                    return <div onClick={() => history.push(`fixture/${item?.fixture_id}`)}>

                                        <div className="flexify cursor-pointer" style={{marginTop: 16}}>
                                
                                            <div className="" style={{fontWeight: 900, textTransform: 'capitalize', display: 'flex'}}>
                                                
                                                <div className="league-img-circle" style={{width: 30, height: 30, backgroundColor: '#2c2c2c'}}>
                                                    <Images height={20} style={{}} source={item.teams_home_logo} alt={item?.teams_home_name ?? 'Name' } />
                                                </div>

                                                <div className="league-img-circle mgl-10" style={{width: 30, height: 30, backgroundColor: '#2c2c2c'}}>
                                                    <Images height={20} style={{}} source={item.teams_away_logo} alt={item?.teams_away_name ?? 'Name'} />
                                                </div>

                                            </div>

                                            <div className="" style={{marginLeft: 10, fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                                                
                                                <div className="" style={{}}>
                                                    {item.teams_home_name} VS {item.teams_away_name}
                                                </div>

                                                <div style={{color: '#c3c3c3'}}>
                                                    {formatDate(item?.fixture_datetime)} {formatTime(item?.fixture_datetime)}, {item.league_name}
                                                </div>

                                            </div>

                                            <div className="" style={{fontWeight: 900, textTransform: 'capitalize', display: 'flex'}}>
                                                
                                                <div style={{}}>
                                                    {item.league_country}
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                })}

                                {fixtureDataToUse.length > allTodaysCountToShow && <div onClick={() => setAllTodaysCountToShow(pre => pre + 30)} className="cursor-pointer" style={{color: '#fff', display: 'flex', marginTop: 10, justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <span style={{marginRight: 5}}>See More</span> <IoArrowForwardCircleOutline />
                                </div>}

                            </div>

                        </div>

                    </div>

                </div>




        </SiteWide>
    );
}

export default AllFixtures;