import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";

import {systemColor} from '../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0
import './personalInformation.css';


import { MdVerifiedUser, MdOutlineVpnLock, MdGroupWork, MdLockClock, MdArrowRightAlt } from "react-icons/md";


function PersonalInformation(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);
    const hiddenFileInput = React.useRef(null);
    const [uploadingAvatar, setUploadingAvatar]           = useState(false);

    
    const systemColorManager = systemColor()

    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }



    React.useEffect(() => {

        loadAllHomePageData();
        

    }, [])



    const dashMenu = [
        

        {
            icon: MdLockClock,
            name: 'Change Password',
            color: '#392',
            to: () => history.push('/change-password'),
            show: true 
        },
        {
            icon: MdLockClock,
            name: 'Change PIN',
            color: '#392',
            to: () => history.push('/change-pin'),
            show: true 
        },
        {
            icon: MdLockClock,
            name: 'Google Auth',
            color: '#392',
            to: () => history.push('/google-auth'),
            show: site_settings.operational_settings.use_google_auth == 1
        },
        
        
    ]



    const dashMenu2 = [
        

        {
            icon: MdLockClock,
            name: 'My Games',
            color: '#392',
            to: () => history.push('/games/mine'),
            show: true 
        },
        {
            icon: MdLockClock,
            name: 'Wallet',
            color: '#392',
            to: () => history.push('/wallet'),
            show: true 
        } 
        
    ]




    const dashMenu3 = [
        

        // {
        //     icon: MdLockClock,
        //     name: 'Deposit',
        //     color: '#392',
        //     to: () => history.push('/deposit-bonus'),
        //     show: true 
        // },
        // {
        //     icon: MdLockClock,
        //     name: 'Referral',
        //     color: '#392',
        //     to: () => history.push('/referral-bonus'),
        //     show: true 
        // },
        // {
        //     icon: MdLockClock,
        //     name: 'Rebate',
        //     color: '#392',
        //     to: () => history.push('/rebate-bonus'),
        //     show: true 
        // } 
        
    ]





    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }


    const uploadAvatar = async (file) => {

        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )

        
    }

    return (
        <SiteWide id="main" title={`${userData.username}'s Profile`}>

                

<div class="contain d-flex justify-content-center" style={{padding: 0, width: '100%'}}>

    <div class="card" style={{width: '80%'}}>
        
        <div class="top-container">
            

            <Images style={{width: 45, height: 45, borderRadius: '50%'}} id="avatarPlace" title="Click to upload" className="cursor-pointer img-fluid profile-image" onClick={progClickFileUpload} width="60px" source={((userData?.image ?? null) != null)?userData?.image:require('../images/athlete1.png')} alt="avatar" />
            <input style={{display: 'none'}} ref={hiddenFileInput} onChange={(e) => uploadAvatar(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
            
            <div class="ml-3">
                <h5 class="name">{userData.username}</h5>
                <p class="mail">{userData.email}</p>
            </div>
        </div>


        <div class="middle-container d-flex justify-content-between align-items-center mt-3 p-2">
                <div class="dollar-div px-3">
                    
                    <div class="round-div"><i class="fa fa-dollar dollar"></i></div>

                </div>
                <div class="d-flex flex-column text-right mr-2">
                    <span class="current-balance">Current Balance</span>
                    <span class="amount"><span class="dollar-sign">$</span>{finance?.[0]?.balance}</span>
                </div>

        </div>


        <div style={{marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <WebLink to={'/deposit'} style={{borderRadius: 50, flex: 1, marginRight: 3}} className="btn bg-success">Deposit</WebLink>
            <WebLink to={'/withdraw'} style={{borderRadius: 50, flex: 1, marginLeft: 3}} className="btn bg-info">Withdraw</WebLink>

        </div>


        <div class="recent-border mt-4" style={{marginBottom: 5}}>
            <span class="recent-orders">Account Menu</span>
        </div>


        {dashMenu2.map((item) => <div onClick={item.to} class="wishlist-border pt-2">
            <span class="wishlist cursor-pointer">{item.name}</span>
        </div>)}




        {/*<div class="recent-border mt-4" style={{marginBottom: 5}}>
            <span class="recent-orders">Bonus</span>
        </div>*/}


        {dashMenu3.map((item) => <div onClick={item.to} class="wishlist-border pt-2">
            <span class="wishlist cursor-pointer">{item.name}</span>
        </div>)}




        <div class="recent-border mt-4" style={{marginBottom: 5}}>
            <span class="recent-orders">Security Panel</span>
        </div>


        {dashMenu.filter((item) => item.show)?.map((item) => <div onClick={item.to} class="wishlist-border pt-2">
            <span class="wishlist cursor-pointer">{item.name}</span>
        </div>)}

        
    </div>
    
</div>






        </SiteWide>
    );

}

export default PersonalInformation;
