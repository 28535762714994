import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {systemColor} from '../utility/styles';

import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import MobileBreaker from "./layouts/MobileBreaker";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, footballSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {footballActions, authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import Helper1, {byNextDate, byToDaysDate, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiMenuLine, RiCheckboxLine } from "react-icons/ri";
import { AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import Cookies from 'js-cookie'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import {FcBookmark, FcBriefcase, FcBrokenLink, FcUpload, FcBusinessContact, FcCableRelease, FcDiploma2} from "react-icons/fc";
// import Ticker from 'react-ticker'
import Marquee from 'react-css-marquee'

import EachFixture from "./Football/EachFixture"

import BetModalPlace from './Football/BetModalPlace';
import parse from 'html-react-parser';


import { AiFillAlert, AiFillWechat, AiFillApi, AiFillAppstore, AiFillBook, AiFillBuild, AiFillBulb } from "react-icons/ai";

// import { HiOutlineChartPie, HiOutlineCalculator, HiOutlineCalendarDays, HiOutlineCalendar, HiOutlineCamera, HiOutlineChartBarSquare, HiOutlineChartBar } from "react-icons/hi2";

import CustomAgo from "./customComponents/CustomAgo";

import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'

import NewsTicker from "./customComponents/Ticker/NewsTicker";
import { PuffLoader } from 'react-spinners';
import SocialMediaIcons from './SocialMediaIcons';
import { IoNotificationsOutline, IoArrowForwardCircleOutline, IoSearchOutline, IoAddCircleOutline, IoNewspaperOutline } from "react-icons/io5";


const nextMatch = {
    slidesToShow: 2.4,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}


const leagueSliders = {
    slidesToShow: 5.4,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}




function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const darkVal = (Cookies.get('darkMode') ?? 1)

    const [isDarkMode, setisDarkMode] = useState(darkVal) //dark mode by default

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData    = useSelector(allTradeSelect);
    const footballData      = useSelector(footballSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);
    const [allTodaysCountToShow, setAllTodaysCountToShow]     = useState(5);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');

    const [recentBets, setRecentBets]   = useState([]);
    const [recentBetsLoading, setRecentBetsLoading]   = useState(true);


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [homeFixture, setHomeFixture]   = useState(byNextDate(footballData?.fixtures));

    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();

    const systemColorManager = systemColor()
    const [betModal, setBetModal]          = React.useState(false);
    const [selectedOddObj, setSelectedOddObj]          = React.useState({});


    const dispatch = useDispatch();

    const ref = useRef();

    
    useEffect(() => {
        setHomeFixture(byNextDate(footballData?.fixtures));
    }, [footballData?.fixtures])


    useEffect(() => {
        loadRecentBets()
    }, [])

    const loadRecentBets = () => {

        if((site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 0){ return; }

        setRecentBetsLoading(true)
        dispatch(footballActions.onGetRecents("football/home-recent", response => {

            setRecentBetsLoading(false)

        }))

    }


    const changeToDarkMode = (booleanVal) => {
        Cookies.set('darkMode', booleanVal, { secure: true, sameSite: 'Lax', expires: 7})
        setisDarkMode(booleanVal)
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])



    
    



    // main slider
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };



    const eachNewsClicked = (item) => {
        history.push(`/news?id=${item.id}`)
    }




    const eachIsClicked = (eachFixture) => {

        if(!userData?.accountId){
            history.push(`signin`)
            return;
        }
        setSelectedOddObj(eachFixture)
        setBetModal(true)
    }



    let recentGames = 0;

// console.log('footballData', footballData)
    const allLeagues = () => {

        const groupedData = footballData?.fixtures?.reduce((acc, curr) => {
          const { league_name } = curr;
          if (!acc[league_name]) {
            acc[league_name] = [];
          }
          acc[league_name].push(curr);
          return acc;

        }, {});

        let leagues_name;

        if (groupedData && typeof groupedData === 'object') {
            leagues_name = Object.values(groupedData);
        } 

        return leagues_name
    }



    const todaysGames = byToDaysDate(homeFixture);


    return (

        <SiteWide isHome overlayText={loadingText} loading={loading}>                




                <div className="row no-gutter">

                    <div className="col-12">

                        <div className="prem h4" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...systemColorManager.tickerStrip_text}}>
                            
                            <div>
                                Leagues
                            </div>

                            <div className="flexify" style={{paddingTop: 6}}>
                                
                               
                                <WebLink to={'/notification'}>
                                    <IoNotificationsOutline className="" />
                                </WebLink>

                            </div>

                        </div>

                        <Slider className="popular-matches-slider" arrows={false} {...leagueSliders}>


                            {allLeagues()?.map((item, index) => {

                                return <WebLink style={{marginRight: 10}} to={`/game?leaguename=${item?.[0].league_name}`}>
                                        <div title={`${item?.[0].league_name} - ${item?.[0].league_country} (${item?.length ?? 0})`} className="" 
                                            style={{width: 60, height: 60, backgroundColor: '#eaeaea', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 0}}>

                                            <Images style={{marginRight: 5, width: 30, height: 30}} source={item?.[0]?.league_logo} alt={item?.[0]?.league_name} />
                                        </div>
                                </WebLink>

                            })}
                          
                        </Slider>


                    </div>
                    
                </div>
                <br /><br />










                <div className="row no-gutter" style={{margin: 0}}>

                    <div className="col-12">

                        <div className="inter-section">


                            <div className="prem h4" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...systemColorManager.tickerStrip_text}}>
                            
                                <div>
                                    Recents
                                </div>

                                <div className="flexify" style={{paddingTop: 6}}>
                                    
                                    {site_settings?.operational_settings?.ticker_news_on_home_allowed == 1 && <WebLink style={{marginRight: 10}} to={'/news'}>
                                        <IoNewspaperOutline className="" />
                                    </WebLink>}

                                    <WebLink style={{marginRight: 10}} to={'/game'}>
                                        <IoAddCircleOutline className="" />
                                    </WebLink>

                                </div>

                            </div>





                            <Slider className="popular-matches-slider" arrows={false} {...nextMatch}>

                                {homeFixture?.map((item, index) => {

                                    recentGames++
                                    if((recentGames%3) == 0){ recentGames = 0}
                                    return <EachFixture number={recentGames} eachIsClicked={eachIsClicked} history={history} fixture={item} />

                                })}
                              
                            </Slider>

                        </div>

                    </div>
                    
                </div>
                <br /><br />







                <div className="row no-gutter">

                    <div className="col-12">

                        <div className="inter-section">
                        
                            <div className="prem h4" style={{...systemColorManager.tickerStrip_text}}>
                                New Events
                            </div>

                            <Slider className="main-home-slider" arrows={false} {...settings}>
                                {site_settings?.slider_settings?.map((item, index) => (
                                    <div style={{}} className="">
                                        <a target="_blank" href={item?.linkWhenClicked}>
                                            <Images style={{borderRadius: 10}} resizeMode="contain" height="200" width="100%" source={item?.image} />
                                        </a>
                                    </div>)
                                )}
                            </Slider>

                        </div>

                    </div>

                </div>
                <br /><br />







                <div className="row no-gutter">

                    <div className="col-12">

                        <div className="inter-section">
                        
                            <div className="prem h4" style={{...systemColorManager.tickerStrip_text}}>
                                Today's Games
                            </div>

                            <div>

                                {todaysGames?.slice(0, allTodaysCountToShow)?.map((item) => {

                                    return <div onClick={() => history.push(`fixture/${item?.fixture_id}`)}>

                                        <div className="flexify cursor-pointer" style={{marginTop: 16}}>
                                
                                            <div className="" style={{fontWeight: 900, textTransform: 'capitalize', display: 'flex'}}>
                                                
                                                <div className="league-img-circle" style={{width: 30, height: 30, backgroundColor: '#2c2c2c'}}>
                                                    <Images height={20} style={{}} source={item.teams_home_logo} alt={item?.teams_home_name ?? 'Name' } />
                                                </div>

                                                <div className="league-img-circle mgl-10" style={{width: 30, height: 30, backgroundColor: '#2c2c2c'}}>
                                                    <Images height={20} style={{}} source={item.teams_away_logo} alt={item?.teams_away_name ?? 'Name'} />
                                                </div>

                                            </div>

                                            <div className="" style={{marginLeft: 10, fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                                                
                                                <div style={{}}>
                                                    {item.teams_home_name} vs {item.teams_away_name}
                                                </div>

                                                <div style={{color: '#2c2c2c'}}>
                                                    {formatTime(item?.fixture_datetime)}, {item.league_name}
                                                </div>

                                            </div>

                                            <div className="" style={{fontWeight: 900, textTransform: 'capitalize', display: 'flex'}}>
                                                
                                                <div style={{}}>
                                                    0-2
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                })}

                                {todaysGames?.length > allTodaysCountToShow && <div onClick={() => setAllTodaysCountToShow(pre => pre + 5)} className="cursor-pointer" style={{color: '#fff', display: 'flex', marginTop: 10, justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <span style={{marginRight: 5}}>See More</span> <IoArrowForwardCircleOutline />
                                </div>}

                            </div>

                        </div>

                    </div>

                </div>






















                    <div style={{marginTop: 28, marginBottom: 28}}>

                        <div className="" style={{borderRadius: 10}}>


                            <div  style={{}}>
                                <p style={{...systemColorManager.txt_3}}>

                                    {parse(site_settings?.content_settings?.[0]?.con3 ?? '')} 

                                    {((site_settings?.content_settings?.[0]?.con1 ?? '') != '') && <WebLink to={'/about'} className="cursor-pointer" style={{textDecoration: 'underline'}}>More</WebLink>}

                                </p>
                            </div>



                            {/*<div className="text-center flexify" style={{marginTop: 10, padding: 20}}>
                                
                                {1>2 && <button style={{...systemColorManager.btn_2, ...systemColorManager.txt_1, borderRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiOutlineMail size={20} /> &nbsp;Support
                                </button>}

                                {((site_settings?.site_settings?.livechat_show_button ?? '') == 1) && <button onClick={() => history.push('customer-care')} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1, marginLeft: 15, borderRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiOutlineComment size={20} /> &nbsp;Live chat
                                </button>}

                                <div style={{display: 'flex', borderRadius: 4, padding: 5, marginLeft: 15}}>
                                    <button onClick={() => changeToDarkMode(0)} style={{...(isDarkMode == 0?systemColorManager.btn_2:{}), ...(isDarkMode == 0?systemColorManager.txt_1:systemColorManager.txt_3), borderTopLeftRadius: 4, borderBottomLeftRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <FaSun size={16} />
                                    </button>
                                    <button onClick={() => changeToDarkMode(1)} style={{...(isDarkMode == 1?systemColorManager.btn_2:{}), ...(isDarkMode == 1?systemColorManager.txt_1:systemColorManager.txt_3), borderTopRightRadius: 4, borderBottomRightRadius: 4, padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <FaCloudMoon size={16} color="" />
                                    </button>
                                </div>

                            </div>*/}



                           


                        </div>



                    </div>
                      

                     <SocialMediaIcons/>






                    <div className="flexify" style={{marginTop: 10, padding: 5, paddingBottom: 20, ...systemColorManager.txt_3}}>
                        <div className="text-center" style={{flex: 1}}>Copyright &copy; {(new Date).getFullYear()} - All right reserved</div>
                    </div>




                    {(site_settings?.site_settings?.showAccreditation ?? 0) == 1 && <div className="flexify" style={{padding: 5, ...systemColorManager.txt_3}}>
                        <div className="text-center" style={{flex: 1}}><a href="mailto:tonyasoftwares@protonmail.com">By Tonya Softwares</a></div>
                    </div>}

                    {userData?.accountId && <BetModalPlace setBettingInAction = {setloading} setBettingInActionText={setLoadingText} betModal={betModal} setBetModal={setBetModal} oddObjSelected = {selectedOddObj} />}

        </SiteWide>
    );
}

export default Home;

