import React, {Component, useState, useEffect} from "react";

import {systemColor} from '../../utility/styles';
import {Images} from "../customComponents";
import { AiFillStar, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import {formatTime, formatDate} from '../../utility/Helper1';


const mainBgs = [
        {bg:'#2649d1', color: '#fff'},
        {bg:'#eaeaea', color: '#000'},
        {bg:'#fc5267', color: '#fff'}
    ]

export default function EachFixture(props) {


    let firstOdd = props?.fixture?.exactScoreOdd?.[props?.fixture?.exactScoreOdd?.length - 1]

    const systemColorManager = systemColor()

    let position = props.number

    let bgColor;

    // if(position%)

    return (<div className="cursor-pointer" style={{marginRight: !props.fromAllPage?'20px':0}}>
            
            <div onClick={() => props.history.push(`fixture/${props?.fixture?.fixture_id}`)} className="" style={{padding: 12, borderRadius: 10, backgroundColor: mainBgs?.[position]?.bg}}>

                <div style={{paddingTop:6}}>

                    <div style={{marginBottom: 15, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                        <strong className="cursor-pointer text-center"
                            style={{backgroundColor: '#fff', width: 50, color: '#000', fontSize: 12, padding: 0, borderRadius: 10}}>
                            {formatTime(props?.fixture?.fixture_datetime)}
                        </strong>

                        <span style={{color: mainBgs?.[position]?.color}} className="cursor-pointer">                            
                            {formatDate(props?.fixture?.fixture_datetime)}
                        </span>

                    </div>



                    <div className="" style={{marginTop: 2, fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                       
                        <div className="" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            
                            <div className="text-center" style={{}}>

                                <div className="league-img-circle" style={{width: 40, height:40, backgroundColor: '#fff'}}>
                                    <Images style={{width: 30, height: 30}} source={props?.fixture?.teams_home_logo} alt={props?.fixture?.teams_home_name} />
                                </div>

                            </div>


                            <div className="text-center" style={{}}>

                                <div className="league-img-circle" style={{width: 40, height:40, backgroundColor: '#fff'}}>
                                    <Images style={{width: 30, height: 30}} source={props?.fixture?.teams_away_logo} alt={props?.fixture?.teams_away_name} />
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="" style={{borderRadius: 10}}>
                    
                    <div style={{marginTop: 10, ...systemColorManager.txt_1, display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', justifyContent: 'space-between'}}>
                        
                        <div style={{marginRight: 3, color: mainBgs?.[position]?.color}} className="ellipsify">
                            {props?.fixture?.teams_home_name}
                        </div>
                        <div style={{color: mainBgs?.[position]?.color}}>
                            {firstOdd?.['home-scores']}
                        </div>

                    </div>


                    <div style={{marginBottom: 10, marginTop: 10, ...systemColorManager.txt_1, display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', justifyContent: 'space-between'}}>
                        
                        <div style={{marginRight: 3, color: mainBgs?.[position]?.color}} className="ellipsify">
                            {props?.fixture?.teams_away_name}
                        </div>
                        <div style={{color: mainBgs?.[position]?.color}}>
                            {firstOdd?.['away-scores']}
                        </div>

                    </div>

                    <br />

                </div>

            </div>

        </div>
    );
}



