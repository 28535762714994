import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide, Images} from "../customComponents";

import {useHistory, useLocation} from 'react-router-dom';
import {systemColor} from '../../utility/styles';
import AuthHolder from "./AuthHolder";

import useScript from '../customComponents/useScript';

import APIRequests from '../../utility/APIRequests';
import {staticCountries} from '../../utility/countries';
import { setUserSession, setUserFinance, setUserToken } from '../../utility/CommonAuth';
import Helper1, {notify} from '../../utility/Helper1';
import {Alert} from "../../alertServices";


import {authActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import { RiEyeOffFill, RiEyeFill } from "react-icons/ri";

let referralToUse = localStorage?.getItem('ref') != 'null'?localStorage?.getItem('ref'):'';

function Signup() {


    const [username, setUsername]                             = useState('');
    const [email, setEmail]                             = useState('');
    const [withdrawalPin, setWithdrawalPin]             = useState('');
    const [showPin, setShowPin]                         = useState(false);
    


    const [password, setPassword]                       = useState('');
    const [loading, changeLoading]                      = useState(false);
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const [loadingText, setLoadingText]                 = useState("");

    const [flashCounter, setFlashCounter]               = useState(0);
    const [showPassword, setShowPassword]               = useState(false);
    const [country, setCountry]             = useState(0); //'156'
    const [phone, setPhone]                 = useState('');
    const [phoneCode, setPhoneCode]           = useState('...'); //'+234'
    const [referral, setReferrals]           = useState(referralToUse); //'+234'
    const [agree, setAgree] = useState(false);



    const [walletAddress, setWalletAddress]             = useState('');
    const [bank, setBankName]                           = useState('');
    const [accountName, setAccountName]                 = useState('');
    const [accountNumber, setAccountNumber]             = useState('');
    const [depositMethod, setDepositMethod]             = useState(100);



    const site_settings = useSelector(allRemoteSettingsSelect);
    const search = useLocation().search;
    const referalLink = new URLSearchParams(search).get("ref");
    const systemColorManager = systemColor()


    const history                                       = useHistory();
    const dispatch = useDispatch();

    const paymentMethod = [
        {
            label: 'Bank',
            tag: 0
        },
        {
            label: 'USDTTRC20',
            tag: 1
        },
    ]

    useEffect(() => {

        if((referalLink != '') && (referalLink!= null) && (referalLink!='null')){
            localStorage.setItem('ref', referalLink);
            // referralToUse = referalLink;
            setReferrals(referalLink)
        }
    }, [])


    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
      }


    const doRegister = async (e) => {



        if((!agree) && ((site_settings?.site_settings?.term ?? '') != '')){
            notify('You must accept the terms and condition to continue', 0);
            return;
        }
        
        e.preventDefault();
        setFullPageLoading(true);
        let financeLoad;

        changeLoading(true)
        setLoadingText("Please wait...")

        let payLoad = {depositMethod, username, email, password, country, phone, withdrawalPin, referral}
        if(depositMethod == 0){
            financeLoad = {bank, accountName, accountNumber}
        }else{
            financeLoad = {walletAddress}
        }
        
        dispatch(authActions.onSignUp("account/register", Object.assign({}, payLoad, financeLoad), response => {

            if(response.code != 0){

                localStorage.removeItem('ref');
                
                // if(response.code == 1){
                    history.replace('/verify-user');
                // }else if(response.code == 2){
                //     history.replace('/personal-information');
                // }
                
            }else{
                notify(response.msg, response.code)
            }

            changeLoading(false)
            setLoadingText("")
            setFullPageLoading(false);
        
        }),
        );


    }


  const changeCountry = (countryId) =>{
    let selectedCountry = staticCountries.filter(e => e.id == countryId) 
    setCountry(countryId);
    let countryPhoneCode = selectedCountry?.[0]?.phonecode;
    setPhoneCode(countryPhoneCode);
    
  }


    return (<SiteWide loading={loading} overlayText={loadingText} title="Register">

                <AuthHolder>



                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Username*</span>
                                <input className="auth-input form-control" onChange={e => setUsername(e.target.value) } placeholder="UserName" required type="text" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                            </label>

                            
                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Email*</span>
                                <input className="auth-input form-control" onChange={e => setEmail(e.target.value) } placeholder="Email" required type="email" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                            </label>


                        
                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Password*</span>
                                <div className="input-group">
                                    <input type={ (showPassword) ? 'text' : 'password'} className="auth-input form-control" onChange={e => setPassword(e.target.value) } required placeholder="Password" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                                    <div className="input-group-append" style={{position: 'absolute', right: 2, top: 5, color: '#fff', zIndex: 5}}>
                                        <span onClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1" style={{...systemColorManager.txt_1}}>
                                            {(showPassword) ? <RiEyeOffFill /> : <RiEyeFill/>}
                                        </span>
                                    </div>
                                </div>
                            </label>


                            {(site_settings?.operational_settings?.request_pin_on_reg ?? 0) == 1 && <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Pin*</span>
                                <div className="input-group">
                                    <input maxLength={site_settings?.operational_settings?.security_pin_length} type={ (showPin) ? site_settings?.operational_settings?.pin_string_format_allowed : 'password'} className="auth-input form-control" value={withdrawalPin} onChange={e => setWithdrawalPin(e.target.value) } required placeholder="Pin" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                                    <div className="input-group-append" style={{position: 'absolute', right: 2, top: 5, color: '#fff', zIndex: 5}}>
                                        <span onClick={() => setShowPin(!showPin)} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1" style={{...systemColorManager.txt_1}}>
                                            {(showPin) ? <RiEyeOffFill /> : <RiEyeFill/>}
                                        </span>
                                    </div>
                                </div>
                            </label>}


                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Country*</span>
                                <select style={{height: 45,...systemColorManager.bg_3, ...systemColorManager.txt_1}} value={country} onChange={e => changeCountry(e.target.value) } required=""  className="auth-input form-control">
                                    <option hidden value="">Select country</option>
                                    {
                                        staticCountries.map((item, index) => 
                                            <option key={index} value={item.id}>{item.nicename}</option>
                                        )
                                    }
                                </select>
                            </label>

                            <label className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Phone*</span>
                                <div className="input-group">
                                    <input type='text' style={{paddingLeft: 80}} className="auth-input form-control" onChange={e => setPhone(e.target.value) } required placeholder="Phone" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1, paddingLeft: 90}}/>
                                    <div className="input-group-prepend" style={{ position: 'absolute', width: 70, textAlign: 'center !important', left: 2, top: 3, zIndex: 99999999, borderRight: '1px solid #cecece'}}>
                                        <span style={{margin: '0 auto', ...systemColorManager.txt_1, paddingTop: 8, }} onFakedClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer no-background" id="basic-addon1">
                                            {phoneCode}
                                        </span>
                                    </div>
                                </div>
                            </label>


                        



                            <label style={{display: 'none'}} className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Invited By:</span>
                                <input defaultValue={referral} className="auth-input" onChange={e => setReferrals(e.target.value) } placeholder="Ref. Code Here (Optional)" type="text" required />
                            </label>


                            {(site_settings?.site_settings?.term ?? '') != '' && <div>
                              <input type="checkbox" id="agree" onChange={checkboxHandler} /> {" "}
                              <label style={{...systemColorManager.txt_1}} htmlFor="agree"> I agree to the <WebLink to={"/term"} style={{textDecoration: 'underline', ...systemColorManager.txt_2}} className="cursor-pointer"><b>terms and conditions</b></WebLink></label>
                            </div>}


                            <div className="container-login100-form-btn mt-40">
                                <button  onClick={doRegister} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">
                                    Sign up
                                </button>
                            </div>



            </AuthHolder>
        </SiteWide>
    );

}

export default Signup;