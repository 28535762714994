import React, {useState, useEffect} from "react";

import {notificationAction} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { notificationSelect } from '../../redux/selectors/index';
import { store } from "../../redux/store";
import {Images} from "./index";
import { RiCloseFill, RiCheckDoubleFill } from "react-icons/ri";
import { FcCancel, FcApproval } from "react-icons/fc";
import MobileBreaker from "../layouts/MobileBreaker";
import { AiFillCloseCircle } from "react-icons/ai";


let hideIn = () =>  new Date(Date.now() + 6000);

export const show = (msg, code) => { store.dispatch(notificationAction.onChangeNotification({show: true, msg: msg, code: code, hideAfter: hideIn()})); }
export const hide = () => { store.dispatch(notificationAction.onChangeNotification({show: false})); }

let timer = 0;

function Notify() {

    // const dispatch = useDispatch();
    const notificationData = useSelector(notificationSelect);

    const hideNotification = () => {
        hide();
    }

    useEffect(() => {
        
        clearTimeout(timer);

        if(notificationData?.show){
            timer = setTimeout(() => {
                    hide();
                }, notificationData?.hideAfter - Date.now())
        }

        
    }, [notificationData?.hideAfter])

    let imageCode = notificationData?.code == 1 ? 1 : 0;

    if(notificationData?.show){
        return  <div className="cursor-pointer" onClick = {() => hideNotification()} 
                    style={{width: '100%',
                            zIndex: '999999999999999999999999',
                            position: 'fixed',
                            bottom: 90,
                            left: 0,
                            borderRadius: 0,
                            backgroundColor: 'transparent',
                            border: '0px solid red'
                            }}>
                    <MobileBreaker>
                        <div style={{marginLeft: 25, marginRight: 25}}>
                            <div className="flexify" style={{borderRadius: 30, background: notificationData?.code == 1?'#fff':'#ff0000', padding: 7, border: `1px solid ${notificationData?.code == 1?'#000':'#fff'}`}}>
                                <div className="" style={{flex: 1, color: notificationData?.code == 1?'#000':'#fff'}}><strong>{notificationData?.msg}</strong></div>
                                <AiFillCloseCircle size={20} color="#000" />
                            </div>
                        </div>
                    </MobileBreaker>


                </div>
    }else{
        return <></>
    }

}

export default Notify;