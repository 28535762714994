import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";

import {mainStyle, mainColor, header, footer, otherColors, systemColor} from '../../utility/styles';
import { useLocation } from 'react-router-dom';
import { allRemoteSettingsSelect } from '../../redux/selectors/index';
import { useSelector } from 'react-redux';



function AuthHolder(props) {

    const { pathname } = useLocation();
    const systemColorManager = systemColor()
    const site_settings = useSelector(allRemoteSettingsSelect);


    return (
        <>

                <div className="" style={{}}>
                    <FullScreenInputHolder>

                         <div className="text-center" style={{padding: 20}}>
                            <Images style={{ height: '50px' }} src={(site_settings?.site_settings?.logo)} alt="logo" />
                        </div>

                        <form className="" style={{border: '0.5px solid #fff', borderRadius: 10}}>

                            {/*<div className="flexify">
                                {!props.customHeadline && <>
                                    <WebLink style={{flex: 1}} to={'/signin'}><button className="btn btn-block" style={{...systemColorManager?.[pathname == '/signin'?'btn_2':'bg_1'], ...systemColorManager.txt_1, borderTopRightRadius: 0, borderBottomRightRadius: 0}}>LOGIN</button></WebLink>
                                
                                    <WebLink style={{flex: 1}} to={'/signup'}><button className="btn btn-block" style={{...systemColorManager?.[pathname == '/signup'?'btn_2':'bg_1'], ...systemColorManager.txt_1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>REGISTER</button></WebLink>
                                </>}

                                {props.customHeadline && <WebLink style={{flex: 1}} to={'#'}><button className="btn btn-block" style={{...systemColorManager?.['btn_2'], ...systemColorManager.txt_1}}>{props.customHeadline}</button></WebLink> }
                            </div>*/}

                           

                            <div style={{padding: 25}}>
                                {props.children}
                            </div>
                        </form>
                    </FullScreenInputHolder>
                </div>

        </>
    );

}

export default AuthHolder;