import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../customComponents";
import {useHistory} from 'react-router-dom';
import APIRequests from '../../utility/APIRequests';
import Helper1, {notify} from '../../utility/Helper1';
import {Alert} from "../../alertServices";
import {mainStyle, mainColor, header, otherColors, systemColor} from '../../utility/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {authActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
// import { auth } from '../redux/actions/auth';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';

function Signin() {

    const dispatch = useDispatch();
    const users = useSelector(userSelect);


    const [email, setEmail]                             = useState('');

    const [password, setPassword]                       = useState('');
    const [loading, changeLoading]                      = useState(false);
    const [loadingText, setLoadingText]                      = useState("");
    const [notificationMessage, setNotificationMessage] = useState('');
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();

    const site_settings = useSelector(allRemoteSettingsSelect);
    const systemColorManager = systemColor()

    const doLogin = async (e) => {

        e.preventDefault();
        setFullPageLoading(true);
        
        changeLoading(true)
        setLoadingText("Authenticating...")

        dispatch(authActions.onLogin("account/login", {username:email, password:password}, response => {

            if(response.code != 0){

                if(response.code == 1){
                    history.replace('/');
                }else if(response.code == 2){
                    history.replace('/verify-user');
                }

            }
            
            notify(response.msg, response.code)
            changeLoading(false)
            setLoadingText("")

            setFullPageLoading(false);
        
        }),
    );

        
    };


    return (<SiteWide loading={loading} overlayText={loadingText} title="Sign in">

            <AuthHolder>
                        
                
                <label className="single-input-wrap">
                    <span className="new-input-label" style={{...systemColorManager.txt_1}}>Username</span>
                    <input placeholder="Username" onChange = {e => setEmail(e.target.value) } required="" className="auth-input" type="text" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                </label>

                <label className="single-input-wrap">
                    <span className="new-input-label" style={{...systemColorManager.txt_1}}>Password</span>
                    <input placeholder="Password" onChange={e => setPassword(e.target.value) } required="" className="auth-input input100" type="password" style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                </label>
                
                
                <div className="container-login100-form-btn mt-40">
                    <button  onClick={doLogin} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">
                        Login
                    </button>
                </div>



                <div style={{textAlign: 'center', marginTop: 25, ...systemColorManager.txt_1}}>
                    
                    <div>
                        <WebLink style={{marginLeft: 'auto'}} className="forgot-btn"  style={{...systemColorManager.txt_1}} to={'/signup'}>
                           Create a new account
                        </WebLink>
                    </div>

                    <div>
                        <WebLink style={{marginLeft: 'auto'}} className="forgot-btn"  style={{...systemColorManager.txt_2}} to={'/reset-password'}>
                           Reset Password
                        </WebLink>
                    </div>

                </div>




            </AuthHolder>

        </SiteWide>
    );

}

export default Signin;