import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';
import CustomAgo from "../customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "../customComponents";

import {RiUser4Line } from "react-icons/ri";
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import {systemColor} from '../../utility/styles';
import './team.css';

import {useHistory, useLocation, useParams} from 'react-router-dom';




function Teamviewier() {    

    const systemColorManager = systemColor()
    const params = useParams();
    const teamlevel = params.level

    const [loadingText, setLoadingText]   = useState('Loading team...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [activeClass, setActiveClass]    = useState(0)
    const history = useHistory();

    const [mainUplineData, setMainUplineData]    = useState({})

    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(teamlevel)
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])

    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoading(false)

            setRefDataRaw(response?.data)
            setRefData(response?.data?.referrals)
            setMainUplineData(response?.data?.upline)

        }))
    }
    
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [teamlevel])


    const showReferral = (site_settings?.operational_settings?.useReferralBonus ?? 0) == 1
    const showRebate = (site_settings?.operational_settings?.useRebateBonus ?? 0) == 1

    const userData = useSelector(userSelect);


    const affiliatePlace = (currentItems) => {
        return <div className="col-12">
            <table className="text-center" style={{backgroundColor: 'transparent', border: 'none'}} className={`table triped ordered hover`}>
              <thead style={{border: 'none', backgroundColor: 'transparent'}}>
                <tr>
                  <th className="text-left" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}></span></th>
                  {showReferral && <th className="text-left" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Referral Earned</span></th>}
                  {showRebate && <th className="text-left" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Rebate Earned</span></th>}
                  {/*<th className="text-right" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Signup date</span></th>*/}
                </tr>
              </thead>
              <tbody style={{border: 'none'}}  className="text-white">

                {currentItems?.map((eachdownline, index) => {

                    let refWithdrawnFromMe = eachdownline?.['refWithdrawnFromMe_' + viewingLevel];
                    let rebWithdrawnFromMe = eachdownline?.['rebateWithdrawnFromMe_' + viewingLevel];

                return <tr style={{border: 'none'}}>
                    <td className="text-left" style={{border: 'none', ...systemColorManager.txt_1}}>
                            <div>{eachdownline?.username}</div>
                            <span>{niceDate(eachdownline?.date)}</span>
                    </td>
                    {showReferral && <td className="text-left" style={{border: 'none', ...systemColorManager.txt_1}}><span>{refWithdrawnFromMe?money(refWithdrawnFromMe):''}</span></td>}
                    {showRebate && <td className="text-left" style={{border: 'none', ...systemColorManager.txt_1}}><span>{rebWithdrawnFromMe?money(rebWithdrawnFromMe):''}</span></td>}
                    {/*<td className="text-right" style={{border: 'none', ...systemColorManager.txt_1}}><span></span></td>*/}
                </tr>
                })}
                

              </tbody>
            </table>
        </div>
    }


    const changeTab = (newTab) => {
        setActiveClass(newTab)
    }


    let totalPlaces = [
                            {title: 'Total Login',                   count: refDataRaw?.['loginCount'] ?? 0}, 
                            {title: 'All Team Capital',             count: money(refDataRaw?.['betEarningAmount'] ?? 0)}, 
                            {title: 'All Team Earning',             count: money(refDataRaw?.['betAmount'] ?? 0)}, 
                            {title: 'All Team Deposit',           count: money(refDataRaw?.['totalDeposits'] ?? 0)},
                            {title: 'All Team Withdrawal',        count: money(refDataRaw?.['totalWithdrawals'] ?? 0)},
                        ]


    let ghjk = site_settings?.referral_settings?.map((item, i) => {
                                                
        let thisCount = i+1
        let backg = systemColorManager.border_2
        let countLev = refData?.['level' + thisCount]?.length ?? 0

        totalPlaces = [...totalPlaces, {title: `View Level ${i+1} team`, count: countLev, to: () => history.push(`referral/level/${i+1}`)}]

    })


    const eachIsClicked = (objOfEach) => {
        if(!objOfEach?.to){ return; }
        objOfEach.to()

    }

    return (<SiteWide overlayBg loading={loading} overlayText={loadingText} title={teamlevel?`Level ${teamlevel} Team-mates`:"Team"}>
        
            <div className="">
                


                <div class="tabs">
                    <div className="">
                    
                        {/*<ul className="tabs-header bg-inf" style={{height: '10px', background: `linear-gradient(to right, transparent, ${systemColorManager.btn_singly_1}, ${systemColorManager.btn_singly_1})`}}>

                            <li className={(activeClass === 0 ? 'active' : '')}>
                                <a style={activeClass == 0?systemColorManager.btn_2:systemColorManager.btn_1} onClick={() => changeTab(0)} >
                                    <span className="prem" style={{color: '#fff'}}>Team today</span>
                                </a>
                            </li>

                            <li className={(activeClass === 1 ? 'active' : '')}>
                                <a style={activeClass == 1?systemColorManager.btn_2:systemColorManager.btn_1} onClick={() => changeTab(1)} >
                                    <span className="prem" style={{color: '#fff'}}>Level analyst</span>
                                </a>
                            </li>

                        </ul>*/}

                        {!teamlevel && <div className="t" style={{zIndex: '99', position: 'relative'}}>


                            <div className={'tItem ' + (activeClass === 0 ? 'show' : '')} >

                                <div className="" style={{}}>

                                    <div className="" style={{}}>
                                        <div style={{}}>
                                            {totalPlaces?.map((item, i) => {
                                                
                                                let backg = systemColorManager.border_2

                                                return <div onClick={() => eachIsClicked(item)} key={i} className="flexify" style={{backgroundColor: '#fff', color: '#000', marginBottom: 8, cursor: 'pointer', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, minWidth: 80, height: 80, 
                                                    ...backg, marginRight: 5, ...systemColorManager.borders_color}} >
                                                    
                                                    <span className="h5 prem">{item?.count}</span>
                                                    <span className="">{item?.title}</span>

                                                </div>

                                            })}

                                        </div>
                                    </div>
                                   
                                </div>


                            </div>

                        </div>}







                        {teamlevel && <div className="" style={{marginTop: 60, padding: 0}}>
                            <div className="row">
                                
                                {affiliatePlace(refData['level'+viewingLevel])}                  

                            </div>
                        </div>}




                    </div>
                </div>









                

                        
                    
            </div>

        </SiteWide>
    );

}

export default Teamviewier;