import React from 'react';
import { Route, Redirect } from 'react-router-dom';



import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../redux/selectors/index';



// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {

  const users = useSelector(userSelect);
  let to = '/verify-user'
  // let to = '/sign-in'


  return (
    
    <Route
      {...rest}
      render={(props) => (((users?.emailVerification ?? 0) == 1) && ((users?.phoneVerification ?? 0) == 1)) ? <Component {...props} /> : <Redirect to={{ pathname: to, state: { from: props.location } }} />}
    />
  )
}

export default PrivateRoute;