import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money, formatTime, formatDate, fixtureStatuses} from '../../utility/Helper1';

import {footballActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";
import TransactionHolder from "../customComponents/TransactionHolder";

import {systemColor} from '../../utility/styles';
import { AiFillStar, AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";

import BetModalPlace from './BetModalPlace';
import DownModal from "../layouts/DownModal";
import { IoCopyOutline, IoStar } from "react-icons/io5";
import copy from 'copy-to-clipboard';


function FixtureDetail() {

    const params = useParams();
    const fixture_id = params.fixture_id


    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Loading history...');
    const footballData                      = useSelector(footballSelect);


    const [filterPanel, setFilterPanel]             = useState(false);

    const [fixtureId, setFixtureId]             = useState('');

    const [loading, setloading]             = useState(false);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    const [thisFixture_detail, setThisFixture_detail]     = useState(footballData?.fixtures?.filter((item, index) => item.fixture_id == fixture_id)?.[0]);
    const [amount, setAmount]               = useState('');

    const [betModal, setBetModal]          = React.useState(false);

    const finance                           = useSelector(homeScreenStatSelect);

    const dispatch                          = useDispatch();
    const systemColorManager                = systemColor()
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0] ?? null);



    const { pathname } = useLocation();

    const isItSearchResultPage = pathname == '/fixture/result'?true:false

    const toggleFilterPannel = () => {
        setFilterPanel(!filterPanel)
    }

    const arrayOfHomeWins = thisFixture_detail?.exactScoreOdd?.filter(function(item, index) {
          return Number(item['home-scores']) > Number(item['away-scores']);
        });

    const arrayOfAwayWins = thisFixture_detail?.exactScoreOdd?.filter(function(item, index) {
          return Number(item['away-scores']) > Number(item['home-scores']);
        });

    const arrayOfDraws = thisFixture_detail?.exactScoreOdd?.filter(function(item, index) {
          return Number(item['away-scores']) == Number(item['home-scores']);
        });


    const oddIsClicked = (oddObj) => {
        setSelectedOddObj(oddObj)
        // setBetModal(true)
    } 



    const doBetRequest = (e) => {

        e.preventDefault()
        if(loading){ return;}

        setloading(true)
        setLoadingText('Placing bet')

        dispatch(footballActions.onSubmitBet({amount, currency: currencySelected.type, betId: selectedOddObj.id}, "football/bet", response => {

            if(response.code == 1){
                setAmount('')
            }
            
            notify(response.msg, response.code)
            setloading(false)
            
            
        }))


    }


    const showOdds = (arrayToUse, note) => {
        return arrayToUse?.map((item) => <div>

            <div className="cursor-pointer" onClick = {() => oddIsClicked(item)} className="row" style={{alignItems: 'center', backgroundColor: selectedOddObj?.id == item.id?'red':'transparent', marginBottom: 5}}>
                       
                <div className="col-2 text-right cursor-pointer">
                    <span className="h4" style={{...systemColorManager.txt_1}}>{item?.['home-scores']}</span>
                </div>


                <div className="col-8 text-center cursor-pointer">
                    {note} - {Number(item?.['percentage-winning'])?.toFixed(1)}%

                    {item.favourite == 1 && <IoStar color="#31ff00" style={{marginLeft: 5}} />}
                </div>

                <div className="col-2 text-left cursor-pointer">
                    <span className="h4" style={{...systemColorManager.txt_1}}>{item?.['away-scores']}</span>
                </div>

                {selectedOddObj?.id == item.id && <div className="col-12 text-left cursor-pointer" 
                    style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10}}>

                    <input placeholder="Bet Amount" value={amount} onChange={e => setAmount(e.target.value)} style={{width: '100%', height: 40, fontSize: 18}} type="text" />
                    <button onClick={doBetRequest} disabled={loading} style={{height: 40, width: 40, backgroundColor: '#000', color: '#fff'}}>Go</button>
                </div>}


            </div>

           

        </div>)
    }







    const checkFixtureResult = () => {

        setLoadingText('Please wait...')
        setloading(true)

        dispatch(footballActions.onSubmitBet({fixtureId}, "football/result/filter", response => {
            
            setThisFixture_detail(response?.data?.filter_result?.[0])
            notify(response?.msg, response?.code)
            setloading(false)
            toggleFilterPannel()

        }))
    }



    const copyThis = (itemToCopy) => {
        copy(itemToCopy)
        notify('Match ID is copied', 1)
    }



    const rightTopMenu = () => {
        return <div className="cursor-pointer" onClick={() => copyThis(thisFixture_detail?.fixture_id ?? '')} style={{color: '#000', marginRight: 10, borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IoCopyOutline color="#fff" />
        </div>
    }


    return (

        <SiteWide rightTopMenu={rightTopMenu} loading={loading} overlayText={loadingText} title={`${thisFixture_detail?.league_name ?? 'League'} - ${thisFixture_detail?.league_country ?? 'Country'}`}>
           
            <div className="" style={{}}>

                <div>

                    <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                        

                        <div style={{backgroundSize: '100% 100%', backgroundPosition: 'center center'}}>

                            <div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
                                

                                {Number(thisFixture_detail?.['recommended-game']) === 1 && <span>
                                    <AiFillStar color={systemColorManager.txt_singly_1} size={15} />
                                </span>}

                            </div>





                            <div className="flexify" style={{padding: 20}}>
                                
                                <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'flex-start', justifyContent: 'flex-start'}}>

                                    <div className="league-img-circle text-center" style={{flexDirection: 'column', justifyContent: 'center', width: 60, height:60, backgroundColor: 'transparent'}}>
                                        <Images style={{width: 60, height:60}} style={{}} source={thisFixture_detail?.teams_home_logo} alt={thisFixture_detail?.teams_home_name} />
                                        <div className="" style={{marginTop: 5, ...systemColorManager.txt_1}}>{thisFixture_detail?.teams_home_name}</div>
                                    </div>

                                    
                                    

                                </div>

                                <div className="text-center">

                                    <div className="" style={{fontSize: 60, ...systemColorManager.txt_1}}>
                                        {thisFixture_detail?.goals_home ?? 0} - {thisFixture_detail?.goals_away ?? 0}
                                    </div>

                                    <div style={{color: '#b75157'}}>
                                        {formatDate(thisFixture_detail?.fixture_datetime ?? '')} {formatTime(thisFixture_detail?.fixture_datetime ?? '')}
                                    </div>


                                </div>

                                 <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'flex-end', justifyContent: 'flex-end'}}>

                                    <div className="league-img-circle text-center" style={{flexDirection: 'column', justifyContent: 'center', width: 60, height:60, backgroundColor: 'transparent'}}>
                                        <Images style={{width: 60, height:60}} style={{}} source={thisFixture_detail?.teams_away_logo} alt={thisFixture_detail?.teams_away_name} />
                                        <div className="" style={{marginTop: 5, ...systemColorManager.txt_1}}>{thisFixture_detail?.teams_away_name}</div>
                                    </div>

                                    

                                </div>

                            </div>

                        </div>


                        {(thisFixture_detail?.fixture_status_short ?? '') != 'NS' && <div className="flexify" style={{}}>
                            
                            <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>

                               <div style={{...systemColorManager.txt_1}}>
                                    <div>H1</div>
                                    <div>{thisFixture_detail?.score_halftime_home}</div>
                                    <div>{thisFixture_detail?.score_fulltime_home}</div>
                                </div>

                            </div>

                            <div className="text-center" style={{width: '50%'}}>
                                
                            </div>

                             <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>

                                <div style={{...systemColorManager.txt_1}}>
                                    <div>H2</div>
                                    <div>{thisFixture_detail?.score_halftime_away}</div>
                                    <div>{thisFixture_detail?.score_fulltime_away}</div>
                                </div>
                            </div>

                        </div>}


                    </div>




                </div>















                {isItSearchResultPage && <div style={{}}>
                    <button onClick={toggleFilterPannel} style={{borderRadius: 0, position: "fixed", bottom: "64px", left: "0", width: "100%",...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">Filter</button>
                </div>}




                {!isItSearchResultPage && <div style={{marginTop: 30}}>



                    <div className="row">
                       
                        <div className="col-12 text-center">
                            <h1 className="text-white">Bet Detail</h1>
                            <small>{thisFixture_detail?.fixture_status_long}</small>
                            <br /><br />
                        </div>

                    </div>



                            {showOdds(arrayOfHomeWins, 'Home Wins')}

                            {showOdds(arrayOfDraws, 'Draw')}

                            {showOdds(arrayOfAwayWins, 'Away Wins')}




                </div>}


            </div>
                





            {isItSearchResultPage && <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={filterPanel} onClose={toggleFilterPannel}>
                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="" style={{maxHeight: 'auto', width: '90%'}}>

                        


                        <form>

                            <div>

                                <div>
                                    <div className="single-input-wrap">

                                        <span className="new-input-label" style={{...systemColorManager.txt_1}}>Fixture Id</span>
                                        <input placeholder={"Fixture Id"} onChange = {e => setFixtureId(e.target.value) } required="" className="form-field form-control" style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}} type="text" />
                                    
                                    </div>
                                </div>


                            </div>

                            <div>
                                <button onClick={checkFixtureResult} type="submit" disabled={loading} className="btn mt-40 btn-block" style={{...systemColorManager.btn_3}}>
                                Submit </button>
                            </div>
                            
                        </form>





                        <div style={{...systemColorManager.txt_1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <span style={{cursor: 'pointer', paddingTop: 4, paddingBottom: 4, }} onClick={toggleFilterPannel}>Close</span>
                        </div>


                    </div>
                </div>

            </DownModal>}
            




            {!isItSearchResultPage && <BetModalPlace setBettingInAction = {setloading} setBettingInActionText={setLoadingText} betModal={betModal} setBetModal={setBetModal} oddObjSelected = {selectedOddObj} />}




            

        </SiteWide>
    );
}

export default FixtureDetail;