import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {systemColor} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';
import Navbar from "./Navbar";

import MobileBreaker from "./MobileBreaker";

import {WebLink, SiteWide, Images} from "../customComponents";
import {useLocation} from "react-router-dom";



function Footer() {



        const { pathname } = useLocation();

        const systemColorManager = systemColor()

        const isPage = (item) => item.address == pathname || 
            (item.address == '/mine' && (pathname == '/signup' || pathname == '/signin' || pathname == '/reset-password'))

        return (
            <>

                <div className="footer-area">
                    

                    <div className="conainer">
                        <div className="footer-bottom" style={{}}>

                            <MobileBreaker>
                                
                                <div  className="main-footer" style={{}}>

                                    <ul style={{...systemColorManager.footer, display: 'flex', color: '#cecece'}}>
                                        
                                        {menuItems.map((item, index) => {

                                            let thisStyle = {}

                                            if(isPage(item)){
                                                thisStyle = {
                                                                borderRadius: 20, height: '100%', backgroundColor: 'transparent', border: '0.5px solid #fff', color: '#fff', display: 'flex', 
                                                                flexDirection: 'row', padding: 5, justifyContent: 'center', alignItems: 'center'
                                                            }
                                            }else{
                                                // thisStyle = {
                                                //                 borderRadius: 50, height: 40, width: 40, backgroundColor: '#fff', color: '#000', display: 'flex', 
                                                //                 flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
                                                //             }
                                            }

                                            return <li className="each-bottom-menu" style={{height: 50, borderRadius: 50}}>

                                            <WebLink style={{...systemColorManager.txt_1, ...thisStyle}} className="ome-clicked" to={item.address}>

                                                <Images className={isPage(item)?'fa-spin':''} height={isPage(item)?15:30} src={item.img} alt="menu icon" />
                                                
                                                {isPage(item) && <p className="prem" style={{marginLeft: 5}}>{item.title.toUpperCase()}</p>}

                                            </WebLink>

                                        </li>})}

                                    </ul>
                                    
                                </div>

                            </MobileBreaker>

                        </div>
                    </div>


                </div>





            </>
        );

}

export default Footer;